import styled from 'styled-components';

import { Button } from '@/ui';

export const StyledButton = styled(Button)<{ background?: string }>`
  background-color: ${({ background }) => background || '#16AC13'};
  border: none;
  border-radius: 0;
  width: 100%;
  line-height: 2;

  &:hover,
  &:focus {
    background-color: ${({ background }) => background || '#16AC13'};
    filter: brightness(85%);
  }
`;

export const FileUploaderWrapper = styled.div`
  .dx-fileuploader-wrapper,
  .dx-fileuploader-input-wrapper {
    padding: 0;
    display: flex;

    :before,
    :after {
      display: none;
    }
  }
`;
