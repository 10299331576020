import React, { FC, useCallback, useState } from 'react';
import { Accordion, Card, Col, DevButton, Row, TextArea } from '@/ui';

import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { AddImagePopup } from '@/modules/Inspection/components/tabs/Damage/components/AddImagePopup';
import {
  useDeleteInspectionDamagesImages,
  useInspectionDamagesImages,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { DamageActions } from '@/modules/Inspection/components/tabs/Damage/components/DamageActions';

import { ImagesLightBox } from '@/ui/components/ImagesLightBox';
import { ImageItem } from '@/ui/components/ImagesLightBox/types';
import { DamageActionsDataGridSectionType } from './types';

export const DamageMasterDetail: FC<DamageActionsDataGridSectionType> = ({
  inspectionId,
  vehicleDamageId,
  note,
  authorizationNotes,
  masterDetailsDataGridProps,
}) => {
  const { t } = useTranslate();
  const [isImagesOpened, setIsImagesOpened] = useState(false);

  const { data: damagesImagesData, isLoading: isDamagesImagesLoading } =
    useInspectionDamagesImages(vehicleDamageId);
  const { mutate: deleteDamagesImagesMutate } =
    useDeleteInspectionDamagesImages();

  const [isImagePopupVisible, setImagePopupVisibility] = useState(false);

  const toggleImagePopup = useCallback(() => {
    setImagePopupVisibility(!isImagePopupVisible);
  }, [isImagePopupVisible]);

  const renderFooter = (imageItem: ImageItem) => (
    <DevButton
      className="text-muted mt-3"
      style={{ display: 'block', width: '200px' }}
      text={t('delete')}
      stylingMode="contained"
      icon="trash"
      onClick={() => deleteDamagesImagesMutate(imageItem.id)}
    />
  );

  return (
    <Row>
      <AddImagePopup
        isPopupVisible={isImagePopupVisible}
        onTogglePopup={toggleImagePopup}
        vehicleDamageId={vehicleDamageId}
      />

      <Col lg={6} className="mt-3 mt-lg-0">
        <Card>
          <DamageActions
            inspectionId={inspectionId}
            vehicleDamageId={vehicleDamageId}
            masterDetailsDataGridProps={masterDetailsDataGridProps}
          />
        </Card>

        <Card className="mt-3">
          <PageContainer fluid>
            <h2>{t('notes')}</h2>

            <TextArea
              className="text-wrap opacity-100"
              value={note}
              disabled
              stylingMode="outlined"
            />
          </PageContainer>
        </Card>

        {authorizationNotes && (
          <Card className="mt-3">
            <PageContainer fluid>
              <h2>{t('authorizationNotes')}</h2>

              <TextArea
                className="text-wrap opacity-100"
                value={authorizationNotes}
                disabled
              />
            </PageContainer>
          </Card>
        )}
      </Col>
      <Col lg={6}>
        {!isDamagesImagesLoading && (
          <Accordion onSelect={(eventKey) => setIsImagesOpened(!!eventKey)}>
            <Accordion.Item eventKey="images">
              <Accordion.Header>
                <h2>{t('images')}</h2>
              </Accordion.Header>
              <Accordion.Body>
                <div className="my-3 d-flex justify-content-start">
                  <DevButton
                    stylingMode="outlined"
                    icon="add"
                    onClick={toggleImagePopup}
                  />
                </div>

                {isImagesOpened && (
                  <ImagesLightBox
                    images={damagesImagesData?.entities?.map((item) => ({
                      id: item.id,
                      imageFileUrl: item.imageFileUrl,
                      thumbnailImageUrl: item.resizedImageUrl,
                    }))}
                    footer={(damagesImage) => renderFooter(damagesImage)}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Col>
    </Row>
  );
};
