import { AxiosPromise } from 'axios';
import {
  UserAssignment,
  CreateUser,
  DeleteUser,
  UpdateUser,
  UsersData,
  CreateUserInTenant,
  DeleteGBAUser,
  TMSUsersData,
  CreateTMSUser,
  DeleteTMSUser,
  UpdateTMSUser,
} from '@/types';
import {
  Core,
  USERS,
  TENANTS,
  GBA_USERS,
  TMS_USERS,
} from '../Core';

type GetUsers = (userId?: number) => AxiosPromise<UsersData>;
type CreateUsers = (data: CreateUser) => AxiosPromise<boolean>;
type CreateUsersInTenant = (data: CreateUserInTenant) => AxiosPromise<boolean>;
type UpdateUsers = (data: UpdateUser) => AxiosPromise<boolean>;
type DeleteUsers = (data: DeleteUser) => AxiosPromise<boolean>;
type DeleteGBAUsers = (data: DeleteGBAUser) => AxiosPromise<boolean>;
type InviteUser = (userId: number) => AxiosPromise<boolean>;
type AssignUsers = (data: UserAssignment) => AxiosPromise<boolean>;
type UnassignUsers = (userId: UserAssignment) => AxiosPromise<boolean>;
type GetTMSUsers = (userId?: number) => AxiosPromise<TMSUsersData>;
type CreateTMSUsers = (data: CreateTMSUser) => AxiosPromise<boolean>;
type DeleteTMSUsers = (data: DeleteTMSUser) => AxiosPromise<boolean>;
type UpdateTMSUsers = (data: UpdateTMSUser) => AxiosPromise<boolean>;

type GetTenantUsers = (tenantId: number) => AxiosPromise<UsersData>;
type GetTenantGBAUsers = (tenantId: number) => AxiosPromise<UsersData>;

class Users extends Core {
  getUsers: GetUsers = async (userId) => this.get(USERS(userId));

  getGBAUsers: GetUsers = async () => this.get(GBA_USERS());

  createUser: CreateUsers = async (data) =>
    this.post<CreateUser, AxiosPromise<boolean>>(USERS(), data);

  createGBAUser: CreateUsers = async (data) =>
    this.post<CreateUser, AxiosPromise<boolean>>(GBA_USERS(), data);

  createUserInTenant: CreateUsersInTenant = async ({ tenantId, ...data }) =>
    this.post<CreateUser, AxiosPromise<boolean>>(USERS(tenantId), data);

  updateUser: UpdateUsers = async (data) =>
    this.patch<UpdateUser, AxiosPromise<boolean>>(USERS(data.id), data);

  updateGBAUser: UpdateUsers = async (data) =>
    this.patch<UpdateUser, AxiosPromise<boolean>>(GBA_USERS(data.id), data);

  deleteUser: DeleteUsers = async (data) =>
    this.delete<DeleteUser, AxiosPromise<boolean>>(USERS(), data);

  deleteGBAUser: DeleteGBAUsers = async (data) =>
    this.delete<DeleteGBAUser, AxiosPromise<boolean>>(GBA_USERS(), data);

  inviteUser: InviteUser = async (userId) =>
    this.patch<number, AxiosPromise<boolean>>(`${USERS(userId)}/invite`);

  inviteGBAUser: InviteUser = async (userId) =>
    this.patch<number, AxiosPromise<boolean>>(`${GBA_USERS(userId)}/invite`);

  getTenantUsers: GetTenantUsers = async (tenantId) =>
    this.get(`${TENANTS(tenantId)}/users`);

  getTenantGBAUsers: GetTenantGBAUsers = async (tenantId) =>
    this.get(`${TENANTS(tenantId)}/gbaUsers`);

  assignGBAUser: AssignUsers = async ({ userId, tenantId }) =>
    this.patch<UserAssignment, AxiosPromise<boolean>>(
      `${GBA_USERS(userId)}/assign/${tenantId}`,
    );

  unassignGBAUser: UnassignUsers = async ({ userId, tenantId }) =>
    this.patch<UserAssignment, AxiosPromise<boolean>>(
      `${GBA_USERS(userId)}/unassign/${tenantId}`,
    );

  getTMSUsers: GetTMSUsers = async () => this.get(TMS_USERS());

  createTMSUser: CreateTMSUsers = async (data) =>
    this.post<CreateTMSUser, AxiosPromise<boolean>>(TMS_USERS(), data);

  deleteTMSUser: DeleteTMSUsers = async (data) =>
    this.delete<DeleteTMSUser, AxiosPromise<boolean>>(TMS_USERS(), data);

  updateTMSUser: UpdateTMSUsers = async (data) =>
    this.patch<UpdateTMSUser, AxiosPromise<boolean>>(TMS_USERS(data.id), data);
}

export const users = new Users();
