import React, { FC, useCallback, useState } from 'react';
import { useTranslate } from '@/translations';
import { Popup, ScrollView } from '@/ui';
import {
  DamageLineFormType,
  CreateDamageLineBody,
} from '@/types/DamagesLines/DamagesLines';
import { useCreateInspectionsVehiclesDamagesLines } from '@/api/Inspections';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useLocationsSites } from '@/api/Locations/hooks/useLocations';
import { Inspection } from '@/types';
import { CarDamageAreaCode } from '@/utils/constants';

import { DamageLineForm } from './DamageLineForm';

const DEFAULT_FORM_DATA: DamageLineFormType = {
  areaCode: '' as CarDamageAreaCode,
  partCode: '',
  damageCauseCode: '',
  sectorIndices: [],
  damageConditionCode: '',
  severityId: '' as unknown as number, // prevent preselect first option in Form.Select
  repairMethodCode: '',
  internalGradingId: '' as unknown as number, // prevent preselect first option in Form.Select
  workCenterCode: '',
  responsibilityCode: '',
  responsibilityShortcutCode: '',
  dealerCode: '',
  note: '',
  mechanicalFailure: '',
  damageImageFiles: [],
};

type Props = {
  isPopupVisible: boolean;
  onTogglePopup: () => void;
  inspection: Inspection;
};

export const AddDamageLinePopup: FC<Props> = ({
  isPopupVisible,
  onTogglePopup,
  inspection,
}) => {
  const idTokenClaims = useIdTokenClaims();
  const tenantId = Number(idTokenClaims?.appTenantId);
  const barCode = inspection.vin;
  const inspectionId = inspection.id;
  const userName = idTokenClaims?.userDisplayName!;

  const { data: locationsSitesData } = useLocationsSites(tenantId, {
    enabled: !!tenantId,
  });

  const siteId = locationsSitesData?.entities.find(
    ({ siteName }) => siteName === inspection.inspectionLocation,
  )?.siteId!;

  const { t } = useTranslate();
  const { mutateAsync: createInspectionsVehiclesDamagesLinesMutateAsync } =
    useCreateInspectionsVehiclesDamagesLines();

  const [formData, setFormData] =
    useState<DamageLineFormType>(DEFAULT_FORM_DATA);
  const [validated, setValidated] = useState<boolean>(false);

  const createDamageLine = useCallback(
    async (body: CreateDamageLineBody) => {
      try {
        const { data } = await createInspectionsVehiclesDamagesLinesMutateAsync(
          body,
        );

        return data;
      } catch (error) {
        console.log('createDamageLine: ', { error });
        return null;
      }
    },
    [createInspectionsVehiclesDamagesLinesMutateAsync],
  );

  const defaultSubmit = useCallback(
    async ({ sectorIndices, ...data }: DamageLineFormType) => {
      const body: CreateDamageLineBody = {
        ...data,
        sectorIndices: sectorIndices.filter(Boolean), // clear 0 values
        tenantId,
        userName,
        inspectionId,
        siteId,
      };

      await createDamageLine(body);
    },
    [tenantId, userName, inspectionId, siteId, createDamageLine],
  );

  const handleTogglePopup = useCallback(() => {
    setFormData(DEFAULT_FORM_DATA);
    setValidated(false);

    onTogglePopup()
  }, [onTogglePopup]);

  const handleSubmit = useCallback(
    async (data: DamageLineFormType) => {
      await defaultSubmit(data);

      handleTogglePopup();
    },
    [defaultSubmit, handleTogglePopup],
  );

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={handleTogglePopup}
      title={t('addDamage')}
      position="center"
      minWidth="420"
      maxWidth="600"
      maxHeight="100vh"
      height="auto"
      closeOnOutsideClick
      showCloseButton
      dragEnabled
    >
      <ScrollView>
        <DamageLineForm
          formData={formData}
          setFormData={setFormData as any}
          validated={validated}
          setValidated={setValidated}
          onSubmit={handleSubmit}
          barCode={barCode}
          siteId={siteId}
        />
      </ScrollView>
    </Popup>
  );
};
