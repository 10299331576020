import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Column,
  DataGrid,
  Editing,
  Export,
  LoadPanel,
  Lookup,
  MasterDetail,
  Pager,
  Paging,
  FilterRow,
  RowPreparedEvent,
  notify,
  ToolbarDataGrid,
  Item as ToolbarItem,
} from '@/ui';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faDownload, Icon } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { InspectionStatusMap } from '@/utils/constants';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import { inspections } from '@/api/Inspections';
import {
  useUpdateInspectionsVehicleDamages,
  useDeleteInspectionsVehiclesDamagesLines,
  useInspectionsVehicleRepairActions,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { ActionsPopup } from '@/modules/Inspection/components/tabs/Damage/components/ActionsPopup';
import { AddDamageLinePopup } from '@/modules/Inspection/components/tabs/Damage/components/DamageLinePopup/AddDamageLine';
import { EditDamageLinePopup } from '@/modules/Inspection/components/tabs/Damage/components/DamageLinePopup/EditDamageLinePopup';
import { useTheme } from 'styled-components';
import { useDataGridExpandedState } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridExpandedState';
import { useDataGridScroll } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridScroll';
import { Damage, SeverityAction } from '@/types';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '@/api/queryKeys';

import { DamageMasterDetail } from './MasterDetail';
import {
  AuthorizationStatus,
  DamageDataGridProps,
  DamageDataGridDataSource,
} from './types';

const PAGE_SIZE = 6;
const INITIAL_VALUES = {
  id: null,
  status: AuthorizationStatus.Default,
};

export const DamageDataGrid: FC<DamageDataGridProps> = ({
  inspection,
  dataSource,
}) => {
  const { t } = useTranslate();
  const { lightGreen, lightRed } = useTheme();
  const { expandedRows, onRowExpanded, onRowCollapsed } =
    useDataGridExpandedState();
  // leave this hook here, to avoid data clearing when re-rendering MasterDetail
  const masterDetailsDataGridProps = useDataGridExpandedState();
  const { ref } = useDataGridScroll({
    dataSource,
    expandedRows,
    // always scroll this dataGrid, cause it's mounted only once and scroll will be fired on each dataSource change
    // scroll here once, instead of scrolling 3+ times in MasterDetail data grid
    shouldScroll: true,
  });
  const { data: repairActionsData } = useInspectionsVehicleRepairActions();

  const [severityActionsData, setSeverityActionsData] = useState<{
    [partCode: string]: SeverityAction[];
  }>({});

  useEffect(() => {
    // TODO: it's a slow performance place, we will do a request for each damage line
    // GET related severities by partCode and damageType
    dataSource.forEach(async (item) => {
      const { data } = await inspections.getInspectionsVehicleSeverityActions(
        item.partCode,
        item.damageType,
      );

      setSeverityActionsData((prevState) => ({
        ...prevState,
        [item.partCode]: data.entities,
      }));
    });
  }, [dataSource]);

  const [comment, setComment] = useState<string>('');
  const [isPopupVisible, setPopupVisibility] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<{
    id: number | null;
    status: AuthorizationStatus;
  }>(INITIAL_VALUES);

  const { mutateAsync: updateVehicleDamageMutateAsync } =
    useUpdateInspectionsVehicleDamages();
  const { mutateAsync: deleteVehicleDamageLineMutateAsync } =
    useDeleteInspectionsVehiclesDamagesLines();

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const handleAuthorizationStatus = useCallback(
    (id: number, status: AuthorizationStatus) => {
      setAuthorization({
        id,
        status,
      });
      togglePopup();
    },
    [togglePopup],
  );

  const handleSaveClick = async () => {
    if (!authorization.id) return;

    try {
      await updateVehicleDamageMutateAsync({
        id: authorization.id,
        authorizationNotes: {
          isChanged: true,
          value: comment,
        },
        authorizationStatus: {
          isChanged: true,
          value: authorization.status,
        },
        severityId: {
          isChanged: false,
        },
        repairActionId: {
          isChanged: false,
        },
      });
      // updated current damage status
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
      // update inspection (costs?)
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
    } catch (error) {
      console.log('handleSaveClick: ', { error });
    }

    setComment('');
    togglePopup();
  };

  const onRowUpdating = useCallback(
    async ({ oldData, newData }) => {
      try {
        await updateVehicleDamageMutateAsync({
          id: oldData.id,
          authorizationNotes: {
            isChanged: false,
          },
          authorizationStatus: {
            isChanged: false,
          },
          severityId: {
            isChanged: !!newData.severityDesc,
            value: newData.severityDesc,
          },
          repairActionId: {
            isChanged: !!newData.repairActionDesc,
            value: newData.repairActionDesc,
          },
        });

        const field = newData.severityDesc ? 'Severity' : 'Repair action';

        notify(
          {
            message: `${field} updated!`,
            position: {
              my: 'top right',
              at: 'top right',
            },
          },
          'success',
          3000,
        );
      } catch (error) {
        console.log('onRowUpdated: ', { error });
        const serverMessage = (error as any).response.data?.entities?.[0]?.message;

        notify(
          {
            message: serverMessage || (error as Error).message,
            position: {
              my: 'top right',
              at: 'top right',
            },
          },
          'error',
          3000,
        );
      }
    },
    [updateVehicleDamageMutateAsync],
  );

  const handleDeleteDamageLine = useCallback(
    async (damageLineId: number) => {
      try {
        await deleteVehicleDamageLineMutateAsync({
          damageLineId,
          inspectionId: inspection.id,
        });
      } catch (error) {
        console.log('handleDeleteDamageLine: ', { error });
      }
    },
    [deleteVehicleDamageLineMutateAsync, inspection],
  );

  const handleCancelClick = () => {
    setComment('');
    setAuthorization(INITIAL_VALUES);
    togglePopup();
  };

  const getColorForAuthorizationStatus = ({
    rowType,
    data,
    rowElement,
  }: RowPreparedEvent) => {
    if (rowType !== 'data') return;

    const status = data?.authorizationStatus;
    const colorMap: { [key: string]: string } = {
      Approved: lightGreen,
      Rejected: lightRed,
    };
    const color = colorMap[status];

    if (color) {
      /* eslint-disable no-param-reassign */
      rowElement.style.backgroundColor = color;
    }
  };

  const [createDamageLinePopupState, setCreateDamageLinePopupState] = useState<{
    isOpen: boolean;
  }>({ isOpen: false });
  const [editDamageLinePopupState, setEditDamageLinePopupState] = useState<{
    isOpen: boolean;
    data?: DamageDataGridDataSource;
  }>({ isOpen: false, data: undefined });

  const toggleCreateDamageLinePopup = useCallback(
    () =>
      setCreateDamageLinePopupState({
        isOpen: !createDamageLinePopupState.isOpen,
      }),
    [createDamageLinePopupState.isOpen],
  );
  const toggleEditDamageLinePopup = useCallback(
    (data?: DamageDataGridDataSource) =>
      setEditDamageLinePopupState({
        isOpen: !editDamageLinePopupState.isOpen,
        data,
      }),
    [editDamageLinePopupState.isOpen],
  );

  const isAllowedToUpdate = useMemo(() =>
      [
        InspectionStatusMap.Inspected,
        InspectionStatusMap['Awaiting Authorisation'],
        InspectionStatusMap['Requires Parts Pricing'],
        InspectionStatusMap['Requires Review'],
        InspectionStatusMap['Requires Re-submission Review'],
      ].includes(inspection.inspectionStatus),
    [inspection],
  );

  const renderSectorIndicesCell = useCallback(
    ({ data: { sectorIndices } }) =>
      <span>{sectorIndices.join(', ')}</span>,
    []);

  const renderImageFileUrlCell = useCallback(
    ({ data: { id, damageImageFileUrls } }) =>
      damageImageFileUrls?.length ? (
        <div className="d-flex flex-column">
          {damageImageFileUrls.map((imageFileUrl: string, idx: number) => (
            <a
              key={imageFileUrl}
              href={imageFileUrl}
              download={`damage id: ${id}, image #${idx+1}`}
              aria-label="Download"
              className="my-1"
            >
              {idx+1}. <Icon icon={faDownload} />
            </a>
          ))}
        </div>
      ) : null,
    [],
  );

  const renderActionsCell = useCallback(
    ({ data }) => {
      const { id } = data;

      return (
        <StyledLinkContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledLink
              onClick={() =>
                handleAuthorizationStatus(id, AuthorizationStatus.Approved)
              }
            >
              {t('approve')}
            </StyledLink>
            <StyledLink
              onClick={() =>
                handleAuthorizationStatus(id, AuthorizationStatus.Rejected)
              }
            >
              {t('reject')}
            </StyledLink>
            {isAllowedToUpdate && (
              <StyledLink onClick={() => toggleEditDamageLinePopup(data)}>
                {t('edit')}
              </StyledLink>
            )}
            {isAllowedToUpdate && (
              <StyledLink onClick={() => handleDeleteDamageLine(id)}>
                {t('delete')}
              </StyledLink>
            )}
          </div>
        </StyledLinkContainer>
      );
    },
    [
      handleAuthorizationStatus,
      toggleEditDamageLinePopup,
      handleDeleteDamageLine,
      t,
      isAllowedToUpdate,
    ],
  );

  const allowFiltering = !!dataSource.length;

  return (
    <>
      <ActionsPopup
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        comment={comment}
        setComment={setComment}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        authorizationStatus={authorization.status}
      />

      {isAllowedToUpdate && (
        <AddDamageLinePopup
          isPopupVisible={createDamageLinePopupState.isOpen}
          onTogglePopup={toggleCreateDamageLinePopup}
          inspection={inspection}
        />
      )}
      {isAllowedToUpdate && editDamageLinePopupState.isOpen && editDamageLinePopupState.data && (
        <EditDamageLinePopup
          isPopupVisible={editDamageLinePopupState.isOpen}
          onTogglePopup={toggleEditDamageLinePopup}
          inspection={inspection}
          damageLine={editDamageLinePopupState.data!}
        />
      )}

      <DataGrid
        ref={ref}
        id="gridContainer"
        dataSource={dataSource}
        columnHidingEnabled
        allowColumnResizing
        columnResizingMode="widget"
        columnAutoWidth
        showRowLines
        showBorders
        repaintChangesOnly
        width="100%"
        onRowPrepared={getColorForAuthorizationStatus}
        onRowUpdating={onRowUpdating}
        onRowExpanded={onRowExpanded}
        onRowCollapsed={onRowCollapsed}
      >
        <LoadPanel enabled />
        <MasterDetail
          enabled
          render={({ data }) => (
            <DamageMasterDetail
              inspectionId={inspection.id}
              vehicleDamageId={data.id}
              note={data.note}
              authorizationNotes={data.authorizationNotes}
              masterDetailsDataGridProps={masterDetailsDataGridProps}
            />
          )}
        />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <FilterRow visible={allowFiltering} />
        <Editing
          mode="cell"
          allowAdding={false} // set false for cell edit, use custom toolbar button + popup instead
          allowUpdating
          allowDeleting={false}
        />
        <Export enabled />
        <ToolbarDataGrid>
          {isAllowedToUpdate && (
            <ToolbarItem
              location="after"
              locateInMenu="auto"
              widget="dxButton"
              options={{
                icon: 'plus',
                onClick: toggleCreateDamageLinePopup,
              }}
            />
          )}
          <ToolbarItem name='exportButton' />
        </ToolbarDataGrid>

        <Column
          dataField="damageAreaDesc"
          caption={t('location')}
          width={150}
          allowEditing={false}
        />
        <Column
          dataField="damagePartDesc"
          caption={t('partComp')}
          allowEditing={false}
        />
        <Column
          dataField="damageType"
          caption={t('damageType')}
          allowEditing={false}
        />
        <Column
          dataField="sectorIndices"
          caption={t('sector')}
          cellRender={renderSectorIndicesCell}
          allowEditing={false}
        />
        <Column
          dataField="severityDesc"
          caption={t('severity')}
          allowEditing={isAllowedToUpdate}
          showEditorAlways
          width={250}
        >
          <Lookup
            dataSource={({ data }: { data: Damage }) => severityActionsData?.[data?.partCode] || []}
            valueExpr="id"
            displayExpr="description"
          />
        </Column>
        <Column
          dataField="repairActionDesc"
          caption={t('repairMethod')}
          width={160}
          allowEditing={isAllowedToUpdate}
          showEditorAlways
        >
          <Lookup
            dataSource={repairActionsData?.entities}
            valueExpr="id"
            displayExpr="repairActionDesc"
          />
        </Column>
        <Column
          dataField="internalGradingPoints"
          caption={t('internalGrade')}
          allowEditing={false}
        />
        <Column
          dataField="workCenterDesc"
          caption={t('workCentre')}
          allowEditing={false}
        />
        <Column
          dataField="responsibilityDesc"
          caption={t('responsibility')}
          allowEditing={false}
        />
        <Column
          dataField="gradePoints"
          caption={t('gradePoints')}
          allowEditing={false}
        />
        <Column
          dataField="lineCost"
          caption={t('lineCost')}
          format={currencyFormatted}
          allowEditing={false}
        />
        <Column
          dataField="imageFileUrl"
          caption={null}
          allowEditing={false}
          allowSorting={false}
          allowFiltering={false}
          cellRender={renderImageFileUrlCell}
          alignment="center"
          width={40}
        />
        <Column
          dataField="actions"
          caption={null}
          cellRender={renderActionsCell}
          alignment="center"
          allowSorting={false}
          allowEditing={false}
          allowSearch={false}
          allowFiltering={false}
          allowExporting={false}
          showEditorAlways={false}
          width={140}
        />
      </DataGrid>
    </>
  );
};
