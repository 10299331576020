import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@/api/queryKeys';
import {
  MechanicalInspection,
  ReturnVehicleCheckListValue,
} from '@/types/Inspection/MechanicalInspection';
import { getFormattedLabel } from '@/utils/helpers';
import { Select } from '@/types/General/Select';

import { mechanicalInspection } from '../mechanicalInspection';

export const useMechanicalInspectionAvailable = (
  barCode: string | number,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery(
    [QUERY_KEYS.mechanicalInspectionAvailable, barCode],
    async () =>
      (await mechanicalInspection.getMechanicalInspectionAvailable(barCode))
        .data,
    options,
  );

export const getMechanicalInspectionItemsByValue = (
  values: MechanicalInspection,
  value: ReturnVehicleCheckListValue,
) =>
  Object.entries(values)?.reduce((acc, [category, categoryItems]) => {
    const res = [...acc];

    Object.entries(categoryItems || {}).forEach(([itemKey, itemValue]) => {
      if (itemValue === value) {
        res.push({
          category,
          item: itemKey,
        });
      }
    });

    return res;
  }, [] as Array<{ category: string; item: string }>);

export const useMechanicalInspection = (
  barCode: string | number,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery(
    [QUERY_KEYS.mechanicalInspection, barCode],
    async () => {
      const { data } = await mechanicalInspection.getMechanicalInspection(
        barCode,
      );

      const failToReportItems = getMechanicalInspectionItemsByValue(
        data,
        ReturnVehicleCheckListValue.FailToReport,
      );

      const failToReportList: Select[] =
        failToReportItems?.map((failItem) => {
          const { item, category } = failItem;
          const value = `${category}.${item}`;
          const label = getFormattedLabel(item);

          return {
            label,
            value,
          };
        });

      return {
        mechanicalList: data,
        isMechanical: !!failToReportItems.length,
        failToReportItems,
        failToReportList,
      };
    },
    options,
  );
