import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslate } from '@/translations';
import {
  CustomStore,
  Column,
  DataGrid,
  Export,
  FilterRow,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
} from '@/ui';
import { faFile, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { localDateTimeFormatted } from '@/utils/helpers';
import {
  SortModelTypeMap,
  FilterModelTypeMapReverse,
  FORMAT_DATE_DD_MM_YYYY_HH_MM,
  InspectionsPageFields,
  InspectionStatusMap,
  InspectionStatusMapLookupFilterValues,
} from '@/utils/constants';
import type { InspectionsPageFieldsType } from '@/types/Inspections';
import { paths } from '@/routes';
import { Link, useSearchParams } from 'react-router-dom';
import { LicensePlate } from '@/ui/components/LicensePlate';
import { useGetUserRole } from '@/hooks';

import { handleExport } from './export';
import { InspectionsDataGridProps } from './types';
import { inspectionsCustomStoreLoad } from './helpers';
import { allowedPageSizes, defaultPageSize } from './constants';

type URLParamsValue = {
  sortType: number;
  isSorted: boolean;
  isFiltered?: boolean;
  filters?: Array<{
    filterType: number;
    filterValue: string;
  }>;
};

type URLParams = Record<'skip' | 'take' | 'search', string | null> &
  Record<keyof InspectionsPageFieldsType, URLParamsValue>;

const getDefaultFilterValue = (
  urlParams: URLParams,
  field: keyof Record<keyof InspectionsPageFieldsType, URLParamsValue>,
  defaultValue?: string,
) => {
  const { isFiltered, filters } = urlParams[field] || { isFiltered: false };

  if (isFiltered && !!filters?.length) {
    return filters[0].filterValue;
  }

  return defaultValue;
};

const getDefaultSelectedFilterOperation = (
  urlParams: URLParams,
  field: keyof Record<keyof InspectionsPageFieldsType, URLParamsValue>,
  defaultValue?: string,
): string | undefined => {
  const { isFiltered, filters } = urlParams[field] || { isFiltered: false };

  if (isFiltered && !!filters?.length) {
    const operator = filters[0].filterType;

    return FilterModelTypeMapReverse[Number(operator) as keyof typeof FilterModelTypeMapReverse];
  }

  return defaultValue;
};

const getDefaultSortOrder = (
  urlParams: URLParams,
  field: keyof Record<keyof InspectionsPageFieldsType, URLParamsValue>,
  defaultValue?: string,
): string | undefined => {
  const { isSorted, sortType } = urlParams[field] || { isSorted: false, sortType: 0 };

  if (isSorted) {
    return Object.entries(SortModelTypeMap).find(([, value]) => value === sortType)?.[0];
  }

  return defaultValue;
};

const getUrlParams = (searchParams: URLSearchParams): URLParams => {
  const newUrlParams = {
    skip: searchParams.get('skip'),
    take: searchParams.get('take'),
    search: searchParams.get('search'),
  } as unknown as URLParams;

  Object.keys(InspectionsPageFields).forEach((field) => {
    const value = searchParams.get(field);

    if (value) {
      newUrlParams[field as unknown as keyof InspectionsPageFieldsType] =
        JSON.parse(value);
    }
  });

  return newUrlParams;
};

export const InspectionsDataGrid: FC<InspectionsDataGridProps> = () => {
  const { t } = useTranslate();
  const { Member } = useGetUserRole();

  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState<URLParams>(getUrlParams(searchParams));

  useEffect(() => {
    const newUrlParams = getUrlParams(searchParams);
    setUrlParams(newUrlParams);
  }, [searchParams]);

  const defaultPageIndex =
    (urlParams.skip &&
      urlParams.take &&
      Number(urlParams.skip) / Number(urlParams.take)) ||
    0;

  const onRenderActionsCell = useCallback(
    ({ data: { id, inspectionStatus } }) => (
      <StyledLinkContainer>
        <StyledLink>
          <Link
            to={`/${paths.inspection}/${id}?inspectionStatus=${inspectionStatus}`}
          >
            <Icon icon={faFile} scale={2} />
          </Link>
        </StyledLink>
      </StyledLinkContainer>
    ),
    [],
  );

  const onRenderRegistration = useCallback(
    ({ data: { regNumber } }) =>
      regNumber ? <LicensePlate>{regNumber}</LicensePlate> : t('noRegNumber'),
    [t],
  );

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load: inspectionsCustomStoreLoad({ isMember: Member }),
      }),
    [Member],
  );

  // Customize SearchPanel to make it outlined instead of filled
  const onEditorPreparing = useCallback((e) => {
    if (e.parentType === "searchPanel") {
      e.editorOptions.stylingMode = "outlined";
    }
  }, []);

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="id"
      columnHidingEnabled
      allowColumnResizing
      columnResizingMode="widget"
      columnAutoWidth
      width="100%"
      onExporting={handleExport}
      remoteOperations
      onEditorPreparing={onEditorPreparing}
    >
      <LoadPanel enabled />
      <SearchPanel visible width="25vw" defaultText={urlParams.search} />
      <FilterRow visible />
      <Paging
        enabled
        defaultPageSize={Number(urlParams.take) || defaultPageSize}
        defaultPageIndex={defaultPageIndex}
      />
      <Pager
        allowedPageSizes={allowedPageSizes}
        displayMode="full"
        showInfo
        showNavigationButtons
        showPageSizeSelector
        visible
      />
      <Export enabled />

      <Column
        dataField={InspectionsPageFields.id}
        caption={t('inspectionId')}
        dataType="number"
        width={100}
        filterOperations={['=', '<>', '<', '>', '<=', '>=']} // 'between' not supported
        defaultFilterValue={getDefaultFilterValue(urlParams, 'id')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'id')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'id')}
      />
      <Column
        dataField={InspectionsPageFields.inspectionRef}
        caption={t('damageReference')}
        dataType="string"
        width={120}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'inspectionRef')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'inspectionRef')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspectionRef')}
      />
      <Column
        key={InspectionsPageFields.file}
        caption=""
        allowFiltering={false}
        width={50}
        alignment="center"
        cellRender={onRenderActionsCell}
        allowExporting={false}
      />
      <Column
        dataField={InspectionsPageFields.regNumber}
        caption={t('registration')}
        dataType="string"
        minWidth={100}
        cellRender={onRenderRegistration}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'regNumber')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'regNumber')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'regNumber')}
      />
      <Column
        dataField={InspectionsPageFields.vin}
        caption={t('vin')}
        dataType="string"
        width={200}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'vin')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'vin')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'vin')}
      />
      <Column
        dataField={InspectionsPageFields.fleetDescription}
        caption={t('customer')}
        dataType="string"
        width={200}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'fleetDescription')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'fleetDescription')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'fleetDescription')}
      />
      <Column
        dataField={InspectionsPageFields.inspectionLocation}
        caption={t('inspectedAt')}
        dataType="string"
        defaultFilterValue={getDefaultFilterValue(urlParams, 'inspectionLocation')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'inspectionLocation')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspectionLocation')}
      />
      <Column
        dataField={InspectionsPageFields.fleetCode}
        caption={t('fleetCode')}
        dataType="string"
        width={100}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'fleetCode')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'fleetCode')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'fleetCode')}
      />
      <Column
        dataField={InspectionsPageFields.inspectionType}
        caption={t('inspectionType')}
        dataType="string"
        width={100}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'inspectionType')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'inspectionType')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspectionType')}
      />
      <Column
        dataField={InspectionsPageFields.model}
        caption={t('model')}
        dataType="string"
        minWidth={150}
        defaultFilterValue={getDefaultFilterValue(urlParams, 'model')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'model')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'model')}
      />
      <Column
        dataField={InspectionsPageFields.inspectionDateTime}
        caption={t('inspectionDate')}
        dataType="datetime"
        minWidth={180}
        width={180}
        format={FORMAT_DATE_DD_MM_YYYY_HH_MM}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspectionDateTime', 'desc')}
        filterOperations={['<', '>']} // 'between' not supported
        defaultFilterValue={
          getDefaultFilterValue(urlParams, 'inspectionDateTime')
            ? new Date(getDefaultFilterValue(urlParams, 'inspectionDateTime') as string)
            : undefined
        }
        defaultSelectedFilterOperation={
          getDefaultSelectedFilterOperation(urlParams, 'inspectionDateTime', FilterModelTypeMapReverse[7])
        }
        cellRender={(props) =>
          localDateTimeFormatted(props.value, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })
        }
      />
      <Column
        dataField={InspectionsPageFields.inspectionStatus}
        caption={t('inspectionStatus')}
        minWidth={180}
        cellRender={({ value }: { value: string }) =>
          InspectionStatusMap[value as keyof typeof InspectionStatusMap]
        }
        defaultFilterValue={
          Member
            ? InspectionStatusMap['Awaiting Authorisation']
            : getDefaultFilterValue(urlParams, 'inspectionStatus')
        }
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'inspectionStatus')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspectionStatus')}
      >
        <Lookup
          dataSource={
            Member
              ? InspectionStatusMapLookupFilterValues[1]
              : InspectionStatusMapLookupFilterValues
          }
          displayExpr="displayExpr"
          valueExpr="id"
        />
      </Column>
      <Column
        dataField={InspectionsPageFields.inspector}
        caption={t('inspector')}
        dataType="string"
        defaultFilterValue={getDefaultFilterValue(urlParams, 'inspector')}
        defaultSelectedFilterOperation={getDefaultSelectedFilterOperation(urlParams, 'inspector')}
        defaultSortOrder={getDefaultSortOrder(urlParams, 'inspector')}
      />
    </DataGrid>
  );
};
