import { useTranslate } from '@/translations';
import { Card, Col } from '@/ui';
import { TranslationKey } from '@/translations/types';
import React from 'react';
import { ImageItem } from '@/ui/components/ImagesLightBox/types';
import { LightBoxItem } from '@/ui/components/ImagesLightBox/ImagesLightBox';
import { TireGroup } from './types';

export const TireCard = ({ tireData }: { tireData: TireGroup }) => {
  const { t } = useTranslate();

  const renderItem = (imageItem: ImageItem) => (
    <Card.Img
      className="border w-auto mw-100 m-1"
      src={imageItem.thumbnailImageUrl}
      loading="lazy"
    />
  );

  const imageItem: ImageItem = {
    id: 0,
    imageFileUrl: tireData.imageFileUrl,
    thumbnailImageUrl: tireData.thumbnailImageUrl,
  };

  const ignoredKeys = ['imageFileUrl', 'thumbnailImageUrl'];

  return (
    <Col className="mb-3">
      <Card>
        <Card.Body>
          {Object.entries(tireData).map(([key, value]) => {
            if (ignoredKeys.includes(key)) {
              return null;
            }

            return (
              <Card.Text
                as="div"
                key={key}
                className="dx-field m-0 d-flex align-items-center"
              >
                <div className="dx-field-label fw-bold">
                  {t(key as TranslationKey)}:
                </div>
                <div className="dx-field-value text-muted">
                  {value || t('notApplicable')}
                </div>
              </Card.Text>
            );
          })}

          {tireData?.imageFileUrl ? (
            <LightBoxItem
              image={imageItem}
              itemRenderer={() => renderItem(imageItem)}
            />
          ) : (
            t('notApplicable')
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};
