import React, { ReactNode } from 'react';
import type { ButtonProps } from 'react-bootstrap';

import { StyledButton } from './Button.styled';

export const Button = ({
  title,
  fullWidth,
  onClick,
  size,
  children,
  disabled,
  variant,
}: {
  title?: string;
  fullWidth?: string;
  onClick?: () => void;
  size?: string;
  children?: ReactNode | undefined;
} & ButtonProps) => (
  <StyledButton
    size={size || 'lg'}
    fullwidth={fullWidth}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
  >
    {title || children}
  </StyledButton>
);
