import React, { FC, useRef } from 'react';
import generatePDF from 'react-to-pdf';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { localDateFormatted } from '@/utils/helpers';
import { TranslationKey } from '@/translations/types';
import { Button } from '@/ui';
import { GbaLogo } from '@/ui/components/Logo/GbaLogo';
import { DynamicOperations, ElectricHybrid, EngineRunning, Inspection, Interior, ReportInitial } from '@/types';
import { MechanicalsWeels } from '@/types/Inspection/Mechanicals';

import RenderIcons from '../../RenderIcons/RenderIcons';
import { Header, Heading, HorizontalList, Li } from '../styles/MechanicalReport.styled';

const destinationKey = ['Ok', 'Requires attention', 'Not applicable'];

type Props = {
  vehicleData: ReportInitial | Inspection;
  interior: Interior;
  checkItems: {
    interior: Interior;
    electricHybrid: ElectricHybrid;
    dynamicOperations: DynamicOperations;
    engineRunning: EngineRunning;
  };
  wheels: MechanicalsWeels;
  checkNSF: string;
  checkOSF: string;
  checkNSR: string;
  checkOSR: string;
};

export const MechanicalReport: FC<Props> = ({
  vehicleData,
  interior,
  checkItems,
  wheels,
  checkNSF,
  checkOSF,
  checkNSR,
  checkOSR,
}) => {
  const { t } = useTranslate();
  const targetRef = useRef(null);

  return (
    <>
      <Button
        onClick={() =>
          generatePDF(targetRef, { filename: 'Mechanical Report.pdf' })
        }
      >
        <span className="dx-font-l">Download Mechanical Report PDF</span>
      </Button>

      <PageContainer ref={targetRef} className="container">
        <Header>
          <div className="row">
            <div className="col-2" />
            <Heading className="col-8">
              <b>Mechanical Report for BMW Group Vehicle</b>
            </Heading>
            <div className="col-2">
              <GbaLogo width="75" height="66" />
            </div>
          </div>
        </Header>

        <div className="row">
          <div className="col-12 my-4">
            <HorizontalList className="d-flex justify-content-between">
              <Li className="d-flex flex-column col-6">
                <span>Vehicle Registration:</span>
                <span>{vehicleData?.regNumber ?? t('noRegNumber')}</span>
              </Li>
              <Li className="d-flex flex-column col-3">
                <span>Chassis:</span>
                <span>{vehicleData?.vin}</span>
              </Li>
              <Li className="d-flex flex-column col-3">
                <span>No of Keys:</span>{' '}
                <span>{interior?.numberOfKeys ?? 0}</span>
              </Li>
            </HorizontalList>

            <HorizontalList className="d-flex justify-content-between align-items-center">
              <Li className="d-flex flex-column col-6">
                <span>Vehicle Model:</span>
                <span>
                  {vehicleData?.manufacturer} {vehicleData?.model}
                </span>
              </Li>
              <Li className="d-flex flex-column col-3">
                <span>Inspection Date:</span>
                <span>
                  {vehicleData?.inspectionDateTime
                    ? localDateFormatted(vehicleData.inspectionDateTime)
                    : null}
                </span>
              </Li>
              <Li className="d-flex flex-column col-3">
                <span>Mileage: </span>{' '}
                <span>
                  {vehicleData?.mileage ??
                    (vehicleData as ReportInitial)?.returnInspectionOdometer
                      ?.mileage}{' '}
                  Miles
                </span>
              </Li>
            </HorizontalList>
          </div>
        </div>

        <div className="row">
          {Object.entries(checkItems).map(([endpoint, endpointData]) => (
            <div key={endpoint} className="col-xs-12 col-sm-6 col-lg-3">
              <h4>
                <span className="fw-bold dx-font-m text-decoration-underline">
                  {t(endpoint as TranslationKey)}
                </span>
              </h4>

              {endpointData && (
                <ul className="list-unstyled">
                  {Object.keys(endpointData)
                    .filter((key) => key !== 'id')
                    .map((question) => {
                      const replacedKey = question.replace(/([A-Z])/g, ' $1');
                      const value = (endpointData as never)[question];

                      return (
                        <Li key={question}>
                          <span>
                            <RenderIcons value={value} />
                          </span>
                          <span className="mx-2">
                            {replacedKey.charAt(0).toUpperCase()}
                            {replacedKey.slice(1)}
                          </span>
                        </Li>
                      );
                    })}
                </ul>
              )}
            </div>
          ))}

          <div className="col-xs-12 col-sm-6 col-lg-3">
            <h4>
              <span className="fw-bold dx-font-m text-decoration-underline">
                Tyre tread
              </span>
            </h4>

            <ul className="list-unstyled">
              <Li>
                <span>
                  <RenderIcons value={checkNSF} />
                </span>
                <span className="mx-2">
                  {`N/S/F ${wheels?.frontLeftWheelInnerDepth ?? 0}mm ${
                    wheels?.frontLeftWheelMiddleDepth ?? 0
                  }mm ${wheels?.frontLeftWheelOuterDepth ?? 0}mm`}
                </span>
              </Li>

              <Li>
                <span>
                  <RenderIcons value={checkOSF} />
                </span>
                <span className="mx-2">
                  {`O/S/F ${wheels?.frontRightWheelInnerDepth ?? 0}mm ${
                    wheels?.frontRightWheelMiddleDepth ?? 0
                  }mm ${wheels?.frontRightWheelOuterDepth ?? 0}mm`}
                </span>
              </Li>

              <Li>
                <span>
                  <RenderIcons value={checkNSR} />
                </span>
                <span className="mx-2">
                  {`N/S/R ${wheels?.rearLeftWheelInnerDepth ?? 0}mm ${
                    wheels?.rearLeftWheelMiddleDepth ?? 0
                  }mm ${wheels?.rearLeftWheelOuterDepth ?? 0}mm`}
                </span>
              </Li>

              <Li>
                <span>
                  <RenderIcons value={checkOSR} />
                </span>
                <span className="mx-2">
                  {`O/S/R ${wheels?.rearRightWheelInnerDepth ?? 0}mm ${
                    wheels?.rearRightWheelMiddleDepth ?? 0
                  }mm ${wheels?.rearRightWheelOuterDepth ?? 0}mm`}
                </span>
              </Li>
            </ul>
          </div>

          <div className="row">
            <div className="col-12">
              <ul className="d-flex justify-content-center align-items-center list-group list-group-horizontal-sm list-group-item">
                <Li className="my-1 mx-3">
                  <b>key:</b>
                </Li>
                {destinationKey.map((key) => (
                  <Li key={key} className="my-1 mx-3">
                    <span className="mx-2">
                      <RenderIcons value={key} />
                    </span>
                    <span>{key}</span>
                  </Li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
