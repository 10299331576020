import { AxiosPromise } from 'axios';
import type {
  MechanicalInspection as MechanicalInspectionType,
  MechanicalInspectionAvailable,
} from '@/types/Inspection/MechanicalInspection';
import {
  Core,
  MECHANICAL_INSPECTION,
  MECHANICAL_INSPECTION_AVAILABLE,
} from '../Core';

type GetMechanicalInspection = (
  barCode: string | number,
) => AxiosPromise<MechanicalInspectionType>;
type GetMechanicalInspectionAvailable = (
  barCode: string | number,
) => AxiosPromise<MechanicalInspectionAvailable>;

class MechanicalInspection extends Core {
  getMechanicalInspection: GetMechanicalInspection = async (barCode) =>
    this.get(MECHANICAL_INSPECTION(barCode));

  getMechanicalInspectionAvailable: GetMechanicalInspectionAvailable = async (
    barCode,
  ) => this.get(MECHANICAL_INSPECTION_AVAILABLE(barCode));
}

export const mechanicalInspection = new MechanicalInspection();
