import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useIdTokenClaims } from '@/hooks';

export const GoogleAnalytics: FC = ({ children }) => {
  const location = useLocation();
  const idTokenClaims = useIdTokenClaims();

  const user = useMemo(() => idTokenClaims?.signInName, [idTokenClaims]);

  useEffect(() => {
    (window as any).dataLayer?.push({
      event: 'Page View',
      location,
      user,
    });
  }, [location, user]);

  return children as React.ReactElement;
};
