import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  useInspectionsVehiclesDamageActionsByFlatRateCode,
  useUpdateInspectionsVehicleDamageActions,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { frCodeValidator, frCodeValidatorBMW } from '@/utils/validations';
import { useTenant } from '@/hooks';
import { Button, Popup, NumberBox, Form, Row, Col } from '@/ui';
import { useTranslate } from '@/translations';
import { Icon, faPlus } from '@/ui/components/Icon';
import { CreateFlatRateCodePopup } from '@/modules/Inspection/components/tabs/Damage/components/CreateFlatRateCodePopup/CreateFlatRateCodePopup';
import { NumberBoxWrapper } from '@/modules/Inspection/components/tabs/Damage/components/CreateFlatRateCodePopup/CreateFlatRateCodePopup.styled';
import { TENANTS } from '@/utils/constants';
import { AddDamageActionBody } from '@/types/Damage/Actions';

import { Title } from './AddActionPopup.styled';
import { AddActionPopupProps } from './types';

const DEFAULT = 'DEFAULT';

const FR_CODE_PLACEHOLDERS = {
  [DEFAULT]: 'AA12345',
  [TENANTS.BMW]: '1234567',
};

export const AddActionPopup: FC<AddActionPopupProps> = ({
  vehicleDamageId,
  isPopupVisible,
  togglePopup,
}) => {
  const { t } = useTranslate();
  const { appTenantName, isBMWTenant } = useTenant();

  const [createFRCodeModalOpen, setCreateFRCodeModalOpen] = useState(false);

  const [frCode, setFrCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<number>(0);

  const isSearchByFrCodeEnabled =
    frCodeValidator(frCode) || (isBMWTenant && frCodeValidatorBMW(frCode));

  const {
    refetch: refetchPartsByFrCode,
    isLoading: isLoadingFrCode,
    isRefetching: isRefetchingFrCode,
  } = useInspectionsVehiclesDamageActionsByFlatRateCode(frCode, {
    enabled: isSearchByFrCodeEnabled,
  });

  const { mutateAsync: mutateAsyncDamageActions } =
    useUpdateInspectionsVehicleDamageActions();

  useEffect(() => {
    if (isSearchByFrCodeEnabled) {
      refetchPartsByFrCode().then((response) => {
        const FRCodeData = response?.data?.entities[0];

        if (!FRCodeData) return;

        setDescription(FRCodeData.flatRateDesc);
        setCost(FRCodeData.fixedLabourCost);
      });
    }
  }, [isSearchByFrCodeEnabled, refetchPartsByFrCode]);

  const onFrCodeChange = useCallback(
    ({ target: { value } }) => setFrCode(value),
    [],
  );

  const onDescriptionChange = useCallback(
    ({ target: { value } }) => setDescription(value),
    [],
  );

  const onQuantityChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    setQuantity(Number(value));
  };

  const onCostChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    const formattedValue = value
      .slice(1) // remove currency symbol
      .trim() // remove white spaces
      .replaceAll(',', ''); // remove a come before converting to number, otherwise it's NaN

    setCost(Number(formattedValue));
  };

  const handleTogglePopup = useCallback(() => {
    setFrCode('');
    setQuantity(1);
    setDescription('');
    setCost(0);

    togglePopup();
  }, [togglePopup]);

  // eslint-disable-next-line
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const body: AddDamageActionBody = {
      vehicleDamageId,
      flatRateCode: frCode,
      quantity,
      price: cost,
    };

    try {
      await mutateAsyncDamageActions(body);
    } catch (error) {
      console.log('error: ', { error });
    } finally {
      handleTogglePopup();
    }
  };

  const renderTitle = () => (
    <span className="mt-3 d-flex justify-content-between">
      <Title>{t('searchItem')}</Title>
      <Button size="sm" onClick={() => setCreateFRCodeModalOpen(true)}>
        <Icon icon={faPlus} />
      </Button>
    </span>
  );

  const onCreateFrCodeCallback = useCallback((data) => {
    setFrCode(data.flatRate);
    setDescription(data.description);
    setCost(data.price);
  }, []);

  const isSubmitDisabled = isLoadingFrCode
    || isRefetchingFrCode
    || !frCode
    || Number.isNaN(Number(cost));

  return (
    <>
      <CreateFlatRateCodePopup
        isPopupVisible={createFRCodeModalOpen}
        togglePopup={setCreateFRCodeModalOpen}
        onSubmitCb={onCreateFrCodeCallback}
      />

      <Popup
        visible={isPopupVisible}
        onHiding={handleTogglePopup}
        titleRender={renderTitle}
        position="center"
        minWidth="320"
        maxWidth="400"
        height="auto"
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="frCode">
            <Form.Label column sm="4">
              {t('frCode')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                value={frCode}
                onChange={onFrCodeChange}
                placeholder={
                  FR_CODE_PLACEHOLDERS[appTenantName as string] ||
                  FR_CODE_PLACEHOLDERS[DEFAULT]
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="description">
            <Form.Label column sm="4">
              {t('description')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                rows={5}
                value={description}
                onChange={onDescriptionChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="quantity">
            <Form.Label column sm="4">
              {t('quantity')}
            </Form.Label>
            <Col sm="8">
              <NumberBoxWrapper>
                <Form.Control
                  as={NumberBox}
                  min={1}
                  max={100}
                  format="#"
                  value={quantity}
                  onChange={onQuantityChange}
                />
              </NumberBoxWrapper>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="cost">
            <Form.Label column sm="4">
              {t('cost')}
            </Form.Label>
            <Col sm="8">
              <NumberBoxWrapper>
                <Form.Control
                  as={NumberBox}
                  min={0}
                  max={50_000}
                  format={{
                    currency: 'GBP',
                    type: 'currency',
                    precision: 2,
                  }}
                  value={cost}
                  onChange={onCostChange}
                />
              </NumberBoxWrapper>
            </Col>
          </Form.Group>

          <div className="mt-3 d-flex gap-2 justify-content-end">
            <Button
              type="submit"
              disabled={isSubmitDisabled}
            >
              {t('save')}
            </Button>
            <Button type="button" onClick={handleTogglePopup}>
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </Popup>
    </>
  );
};
