import React, { FC, useCallback, useMemo } from 'react';

import {
  Card,
  Col,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
  MasterDetail,
} from '@/ui';
import { useTranslate } from '@/translations';
import { useInspectionsVehicleDamageActions } from '@/api/Inspections';
import { faTrash, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer, StyledLink } from '@/ui/globalStyles';
import { useDeleteInspectionsVehicleDamageAction } from '@/api/Inspections/hooks';
import { DamageParts } from '@/modules/Inspection/components/tabs/Damage/components/DamageParts';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import { useDataGridScroll } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridScroll';

import { DamageActionsDataGridProps } from './types';

const PAGE_SIZE = 6;

export const DamageActionsDataGridSection: FC<DamageActionsDataGridProps> = ({
  inspectionId,
  vehicleDamageId,
  masterDetailsDataGridProps,
}) => {
  const { t } = useTranslate();
  const { data: damageActionsData } =
    useInspectionsVehicleDamageActions(vehicleDamageId);
  const { mutateAsync: deleteVehicleDamageActionMutateAsync } =
    useDeleteInspectionsVehicleDamageAction();

  const handleDeleteDamageAction = useCallback(async (damageActionId: string) => {
    try {
      await deleteVehicleDamageActionMutateAsync({
        damageActionId,
        inspectionId,
      });
    } catch (error) {
      console.log('handleDeleteDamageAction: ', { error });
    }
  }, [deleteVehicleDamageActionMutateAsync, inspectionId]);

  const renderActionsCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink
          onClick={() => handleDeleteDamageAction(id.toString())}
        >
          <Icon icon={faTrash} />
        </StyledLink>
      </StyledLinkContainer>
    ),
    [handleDeleteDamageAction],
  );

  const dataSource = useMemo(
    () =>
      damageActionsData?.entities.map((e) => ({
        ...e,
        lineCost: e.price * e.quantity,
      })),
    [damageActionsData],
  );

  const { expandedRows, onRowCollapsed, onRowExpanded } =
    masterDetailsDataGridProps;

  const { ref } = useDataGridScroll({
    dataSource,
    expandedRows,
    // expand rows but not scroll, this dataGrid can be mounted/unmounted 3+ times, because it is in a MasterDetail grid of DamageDataGrid
    shouldScroll: false,
  });

  return (
    <DataGrid
      dataSource={dataSource}
      columnHidingEnabled
      allowColumnResizing
      allowColumnReordering
      columnAutoWidth
      showRowLines
      showBorders
      width="100%"
      ref={ref}
      onRowCollapsed={onRowCollapsed}
      onRowExpanded={onRowExpanded}
    >
      <LoadPanel enabled />
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Editing mode="popup" confirmDelete={false} />
      <MasterDetail
        enabled
        render={({ data }) => (
          <Col className="mt-3 mt-lg-0">
            <Card>
              <DamageParts
                damageActionId={data.id}
                flatRateCode={data.flatRateCode}
              />
            </Card>
          </Col>
        )}
      />

      <Column dataField="flatRateCode" caption={t('code')} />
      <Column dataField="description" caption={t('description')} />
      <Column dataField="quantity" caption={t('quantity')} />
      <Column
        dataField="price"
        caption={t('cost')}
        format={currencyFormatted}
      />
      <Column
        dataField="lineCost"
        caption={t('lineCost')}
        format={currencyFormatted}
      />
      <Column
        dataField="actions"
        minWidth={80}
        width={80}
        alignment="center"
        cellRender={renderActionsCell}
        allowEditing={false}
        allowSorting={false}
        allowFiltering={false}
        allowResizing={false}
      />
    </DataGrid>
  );
};
