export enum ReturnVehicleCheckListValue {
  Pass = 'Pass',
  Fail = 'Fail',
  FailToReport = 'FailToReport',
  NotApplicable = 'NotApplicable',
}

enum YesNo {
  no = 'No',
  yes = 'Yes',
}

type ElectricCheckList = {
  vehicleAcceptsCharge?: ReturnVehicleCheckListValue;
  chargePortCondition?: ReturnVehicleCheckListValue;
  modeTwoCablePresent?: ReturnVehicleCheckListValue;
  vehicleChargeLevel?: ReturnVehicleCheckListValue;
  modeThreeCablePresent?: ReturnVehicleCheckListValue;
  coolantLevel?: ReturnVehicleCheckListValue;
};

type InteriorCheckList = {
  numberOfKeys?: number;
  fuelLevel?: number;
  motDate: string;
  isV5Present: YesNo;
  breakFunctionTest?: ReturnVehicleCheckListValue;
  handBrakeTest?: ReturnVehicleCheckListValue;
  steeringNoise?: ReturnVehicleCheckListValue;
  staticGearSelection?: ReturnVehicleCheckListValue;
  checkCorrectTimeAndDateSet: ReturnVehicleCheckListValue;
  checkEngineManagementLight: ReturnVehicleCheckListValue;
  checkAudioDisplaySystems: ReturnVehicleCheckListValue;
  checkDscWarningLight: ReturnVehicleCheckListValue;
  batteryNoErrorMessage: ReturnVehicleCheckListValue;
  checkAirBagWarningLight: ReturnVehicleCheckListValue;
  checkAllWindow: ReturnVehicleCheckListValue;
  checkSunroofConvertibleRoof: ReturnVehicleCheckListValue;
  checkCentralLocking: ReturnVehicleCheckListValue;
  checkHandbook: ReturnVehicleCheckListValue;
  checkAirbagStatus: ReturnVehicleCheckListValue;
  checkAirConditionFunctionality: ReturnVehicleCheckListValue;
  checkWipersAndWashes: ReturnVehicleCheckListValue;
  checkHorn: ReturnVehicleCheckListValue;
  checkSeatbeltFunction: ReturnVehicleCheckListValue;
  checkNavFunctionality: ReturnVehicleCheckListValue;
};

type IstaCheckList = {
  carryOutIstaVehicleTest: ReturnVehicleCheckListValue;
  deletePersonalData: ReturnVehicleCheckListValue;
  readKeyPositions: ReturnVehicleCheckListValue;
};

type RunningEngineBayChecklist = {
  checkBrakeDiscsPads: ReturnVehicleCheckListValue,
  checkBrakeFluidLevel: ReturnVehicleCheckListValue,
  checkCoolantLevel: ReturnVehicleCheckListValue,
  checkExteriorLights: ReturnVehicleCheckListValue,
  checkOilLevel: ReturnVehicleCheckListValue,
  engineRunningSmokingNoise: ReturnVehicleCheckListValue,
  exhaustNoiseSecure: ReturnVehicleCheckListValue,
  shortDrive: ReturnVehicleCheckListValue,
  suspensionVehicleLevel: ReturnVehicleCheckListValue,
};

type VehicleWheelSize = {
  rimRadius: number;
  tyreBrand: string;
  tyreHeight: number;
  tyreLoad: number;
  tyreSpeed: string;
  tyreWidth: number;
  wheelInnerDepth: string;
  wheelMiddleDepth: string;
  wheelOuterDepth: string;
  wheelType: string;
};

export type MechanicalInspection = {
  electricChecklist: ElectricCheckList,
  interiorCheckList: InteriorCheckList,
  istaCheckList: IstaCheckList,
  runningEngineBayChecklist: RunningEngineBayChecklist,
  summary: {
    comments: string,
  },
  wheelDetails: {
    frontLeftWheel: VehicleWheelSize,
    frontRightWheel: VehicleWheelSize,
    rearLeftWheel: VehicleWheelSize,
    rearRightWheel: VehicleWheelSize,
    spareWheel: VehicleWheelSize,
  },
};

export type MechanicalInspectionAvailable = {
  inspectionAvailable: boolean;
};
