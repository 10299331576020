import React, { useCallback, useState, FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { DevButton } from '@/ui';
import { DamageActionsDataGridSection } from '@/modules/Inspection/components/tabs/Damage/components/DamageActions/DamageActionsDataGridSection';
import { useTranslate } from '@/translations';
import { AddActionPopup } from '@/modules/Inspection/components/tabs/Damage/components/AddActionPopup/AddActionPopup';

import { DamageActionsProps } from './types';

export const DamageActions: FC<DamageActionsProps> = ({
  inspectionId,
  vehicleDamageId,
  masterDetailsDataGridProps,
}) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  return (
    <PageContainer fluid>
      <h2>{t('frCodes')}</h2>

      <div className="my-3 d-flex justify-content-end">
        <DevButton stylingMode="outlined" icon="add" onClick={togglePopup} />
      </div>

      <DamageActionsDataGridSection
        inspectionId={inspectionId}
        vehicleDamageId={vehicleDamageId}
        masterDetailsDataGridProps={masterDetailsDataGridProps}
      />

      <AddActionPopup
        vehicleDamageId={vehicleDamageId}
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
      />
    </PageContainer>
  );
};
