import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, DevButton, Form, Popup, Row, SelectBox, TagBox } from '@/ui';
import { Title } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { useTranslate } from '@/translations';
import { InitialValues, CreateTMSUserPopupProps } from './types';

const initialValues: InitialValues = {
  displayName: '',
  userName: '',
  password: '',
  inspectorId: '',
  siteIds: [],
};

export const CreateTMSUserPopup: FC<CreateTMSUserPopupProps> = ({
  sites,
  inspectors,
  tenants,
  tenantId,
  onSelectTenantId,
  onAddTMSUser,
  isCreatePopupVisible,
  onToggleCreatePopup,
}) => {
  const { t } = useTranslate();

  const [tmsData, setTmsData] = useState<InitialValues>(initialValues);

  const renderTitle = () => (
    <span className="mt-3 d-inline-block">
      <Title>{t('createTMSUser')}</Title>
    </span>
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  const handleTogglePopup = useCallback(
    () => {
      setTmsData(initialValues);
      onToggleCreatePopup();
    },
    [onToggleCreatePopup],
  );

  const handleSubmit = useCallback(() => {
    onAddTMSUser({
      ...tmsData,
      tenantId: Number(tenantId),
      inspectorId: Number(tmsData.inspectorId) || undefined,
    });
    handleTogglePopup();
  }, [tmsData, onAddTMSUser, tenantId, handleTogglePopup]);

  return (
    <Popup
      visible={isCreatePopupVisible}
      onHiding={handleTogglePopup}
      closeOnOutsideClick
      titleRender={renderTitle}
      position="center"
      minWidth="320"
      maxWidth="520"
      height="auto"
    >
      <Form>
        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="tenant"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('tenant')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              value={tenantId}
              as={SelectBox}
              items={tenants}
              valueExpr="id"
              displayExpr="name"
              onValueChange={(e: string) => onSelectTenantId(e)}
              placeholder={t('chooseTenant')}
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="displayName"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('displayName')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100"
              onChange={({ target }) =>
                setTmsData({ ...tmsData, displayName: target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="username"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('username')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100"
              onChange={({ target }) =>
                setTmsData({ ...tmsData, userName: target.value })
              }
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="password"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('password')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100"
              onChange={({ target }) =>
                setTmsData({ ...tmsData, password: target.value })
              }
              required
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="inspector"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('inspector')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              as={SelectBox}
              items={inspectorsDataSource}
              valueExpr="id"
              displayExpr="displayExpr"
              onValueChange={(e: string) => {
                setTmsData({ ...tmsData, inspectorId: e });
              }}
              placeholder={t('chooseInspector')}
              showClearButton
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="siteIds"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('site')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              as={TagBox}
              items={sites}
              valueExpr="siteId"
              displayExpr="siteName"
              onValueChange={(e: []) => setTmsData({ ...tmsData, siteIds: e })}
              placeholder={t('chooseSites')}
            />
          </Col>
        </Form.Group>

        <div className="mt-3 d-flex gap-2 justify-content-end px-2">
          <DevButton
            text={t('save')}
            stylingMode="outlined"
            onClick={handleSubmit}
            disabled={!tmsData.displayName || !tmsData.password || !tenantId}
          />

          <DevButton
            text={t('cancel')}
            stylingMode="outlined"
            onClick={handleTogglePopup}
          />
        </div>
      </Form>
    </Popup>
  );
};
