import React, { FC, useState, useEffect } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { Image as RbImage } from '@/ui';
import { ImagesLightBoxProps, LightBoxItemProps } from './types';

export const LightBoxItem: FC<LightBoxItemProps> = ({
  image,
  itemRenderer,
}) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const thumbnailUrl = image.thumbnailImageUrl || image.imageFileUrl;

  useEffect(() => {
    const img = new Image();
    if (thumbnailUrl) {
      img.src = thumbnailUrl;
      img.onload = () => {
        setDimensions({ width: img.width, height: img.height });
      };
    }

    return () => {
      img.onload = null;
    };
  }, [thumbnailUrl]);

  return dimensions ? (
    <Item<HTMLElement>
      original={image.imageFileUrl}
      thumbnail={thumbnailUrl}
      alt={image.id.toString()}
      width={dimensions.width * 5}
      height={dimensions.height * 5}
    >
      {({ ref, open }) => (
        <button
          ref={ref}
          onClick={open}
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
          }}
        >
          {itemRenderer ? (
            itemRenderer(image)
          ) : (
            <RbImage
              src={thumbnailUrl}
              alt={image.id.toString()}
              style={{ width: '200px' }}
              loading="lazy"
            />
          )}
        </button>
      )}
    </Item>
  ) : null;
};

export const ImagesLightBox: FC<ImagesLightBoxProps> = ({ images, footer }) =>
  images && images.length > 0 ? (
    <Gallery>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
          gridGap: '12px',
        }}
      >
        {images.map((image) => (
          <div>
            <LightBoxItem key={image.id} image={image} />
            {footer && footer(image)}
          </div>
        ))}
      </div>
    </Gallery>
  ) : null;
