import styled, { createGlobalStyle, css } from 'styled-components';
import icomoonSvg from './fonts/icomoon.svg';
import icomoonEot from './fonts/icomoon.eot';
import icomoonWoff2 from './fonts/icomoon.woff2';
import icomoon from './fonts/IcoMoon-Free.ttf';

import 'devextreme/dist/css/dx.material.blue.light.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export const GlobalStyledLink = css`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.accent};
  transition: all 1s ease-out;

  &:hover {
    opacity: 0.4;
    color: ${({ theme }) => theme.accent};
    fill: ${({ theme }) => theme.accent};
    transition: all 0.4s ease-out;
  }
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a,
  span {
    padding: 0 8px;
  }
`;

export const StyledLink = styled.span`
  ${GlobalStyledLink}
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'icomoon';
    src: url(${icomoonEot}) format('embedded-opentype'), url(${icomoon}) format('truetype'), url(${icomoonWoff2}) format('woff2'), url(${icomoonSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  .dx-datagrid-header-panel .dx-toolbar-items-container {
    height: 56px;
  }

  .dx-editor-cell .dx-dropdowneditor,
  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    background-color: transparent;
  }

  .dx-texteditor.dx-editor-outlined,
  .dx-texteditor.dx-editor-outlined.dx-state-hover {
    box-shadow: none;
  }

  .dx-texteditor.dx-state-hover,
  .dx-texteditor.dx-editor-outlined.dx-state-focused {
    box-shadow: none;
    border: 1px solid #ddd;
  }

  .dx-texteditor.dx-editor-outlined.dx-state-disabled,
  .dx-texteditor.dx-editor-outlined.dx-state-readonly,
  .dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover,
  .dx-texteditor.dx-editor-filled.dx-state-disabled,
  .dx-texteditor.dx-editor-filled.dx-state-readonly,
  .dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover {
    box-shadow: none;
  }

  .dx-texteditor.dx-state-readonly.dx-editor-filled,
  .dx-texteditor.dx-state-readonly.dx-editor-filled.dx-state-hover,
  .dx-texteditor.dx-state-readonly.dx-editor-outlined,
  .dx-texteditor.dx-state-readonly.dx-editor-outlined.dx-state-hover  {
    background-color: transparent;
  }

  .dx-texteditor.dx-editor-outlined.dx-state-disabled .dx-texteditor-input,
  .dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-input,
  .dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover .dx-texteditor-input,
  .dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input,
  .dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input,
  .dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover .dx-texteditor-input {
    color: black;
  }
`;
