import { useEffect } from 'react';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

import { WS_VEHICLE_INSPECTION_HUB } from '@/api/Core/endpoints/WebSockets';
import { QUERY_KEYS } from '@/api/queryKeys';
import { queryClient } from '@/config/reactQuery';
import { Damage, DamageAction, Inspection, InspectionsData } from '@/types';

enum HUB_METHODS_NAMES {
  SendVehicleInspectionsToUser = 'SendVehicleInspectionsToUser',
}

export const useWebSockets = (isAuthenticated = false) => {
  useEffect(() => {
    if (!isAuthenticated) return;

    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_URL}${WS_VEHICLE_INSPECTION_HUB}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    connection.start()
      .then(() => {
        console.log('WebSocket connection initialized!');
      })
      .catch((err) => {
        console.error('WebSocket connection failed!', err);
      });

    // methodName - The name of the hub method to define
    connection.on(HUB_METHODS_NAMES.SendVehicleInspectionsToUser, async (data: { entities: [Inspection]}) => {
      const inspection = data?.entities?.[0];

      if (!inspection) return;

      // user will get this method when somebody will:
      // 1. Add Damage line
      // 2. Update Damage line
      // 3. Delete Damage line
      // 4. Add Damage action(FR Code)
      // 5. Delete Damage action(FR Code)
      // 6. Add Part Number
      // 7. Delete Part number

      // try to find inspection in user cache to update it, or skip refetch
      const inspectionFromCache = queryClient.getQueryData<{ entities: Inspection[] }>(
        [QUERY_KEYS.inspectionsVehicle, inspection.id]
      );

      if (!inspectionFromCache) return;

      // update Inspection (update costs)
      queryClient.setQueryData(
        [QUERY_KEYS.inspectionsVehicle, inspection.id],
        (oldData) => ({
          ...oldData as InspectionsData,
          entities: (oldData as InspectionsData).entities.map(
            (item) => item.id === inspection.id ? inspection : item
          ),
        }),
      );

      /** DAMAGES */
      const inspectionDamagesFromCache = queryClient.getQueryData<{ entities: Damage[] }>(
        [QUERY_KEYS.damages, inspection.id]
      );

      if (!inspectionDamagesFromCache?.entities?.length) return;

      // refetch Damages
      queryClient.invalidateQueries([QUERY_KEYS.damages, inspection.id]);

      inspectionDamagesFromCache.entities.forEach((damage: Damage) => {
        /** DAMAGE ACTIONS (FR CODES) */
        const inspectionDamageActionsFromCache = queryClient.getQueryData<{ entities: DamageAction[] }>(
          [QUERY_KEYS.damageActions, damage.id]
        );

        if (!inspectionDamageActionsFromCache?.entities?.length) return;

        // refetch Damage Actions
        queryClient.invalidateQueries([QUERY_KEYS.damageActions, damage.id]);

        inspectionDamageActionsFromCache.entities.forEach((damageAction) => {
          /** PART NUMBERS */
          const inspectionDamageActionPartsFromCache = queryClient.getQueryData<{ entities: any[] }>(
            [QUERY_KEYS.damagePartsById, damageAction.id]
          );

          if (!inspectionDamageActionPartsFromCache?.entities?.length) return;

          // refetch Damage Action Parts
          queryClient.invalidateQueries([QUERY_KEYS.damagePartsById, damageAction.id]);
        });
      });
    });

    // eslint-disable-next-line
    return () => {
      connection.stop()
    };
  }, [isAuthenticated]);
};
